<template>
  <div class="station-page">
    <van-nav-bar
      :title="this.stationName"
      left-text="返回"
      right-text="首页"
      left-arrow
      @click-left="goBack"
      @click-right="goIndex"
    />
    <div class="loading-div" v-show="loading"><van-loading size="24px" vertical>加载中...</van-loading></div>
    <div class="station-lines" v-show="!loading">
      <div v-for="line in lineList" :key="line.name">
        <div class="line-group" @click="queryByLine(line.lineName, line.upperOrDown, line.to)">
          <div class="float-left">
            <div class="van-multi-ellipsis--l2">
              <span class="line-name">{{ line.lineName }}</span>
            </div>
            <div class="van-ellipsis">
              <span class="line-to">方向&#32;</span>
              <span class="line-to">{{ line.to }}</span>
            </div>
          </div>
          <div class="float-right">
            <div>
              <span class="line-neartext">{{ line.neartext }}</span>
            </div>
            <div>
              <span class="line-neardis">{{ line.neardis }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Toast } from 'vant'
import { linesByStationName } from '@/api/mygolbs'
export default {
  name: 'Station',
  props: ['stationName'],
  data() {
    return {
      loading: true,
      lineList: [],
    }
  },
  computed: {
    ...mapGetters(['location', 'stationLat', 'stationLng']),
  },
  activated() {
    this.init()
  },
  methods: {
    ...mapActions(['Position', 'turnToSchedule', 'turnToLine']),
    goBack() {
      Toast('返回')
      this.$router.back()
    },
    goIndex() {
      this.$router.push({ name: 'Home' })
    },
    init() {
      this.Position()
        .then(res => {
          if (res.status == 200) {
            this.queryByStationNameNotAll(this.stationName, this.stationLat, this.stationLng, 1)
          }
        })
        .catch(error => {
          Toast(error)
        })
    },
    //按站点查询（没有全部）
    queryByStationNameNotAll(stationName, lat, lon, isAllLines) {
      this.loading = false
      console.log('???????')
      linesByStationName(this.location.city, stationName, lat, lon, isAllLines).then(res => {
        console.log('res:', res)
        const data = res.data
        this.lineList = data
      })
    },
    //按线路查询
    queryByLine(lineName, upperOrDown, to) {
      let params = { lineName: lineName, upperOrDown: upperOrDown, to: to }
      this.turnToLine(params)
      this.$router.push({ name: 'BusLine', params: { lineName: lineName, upperOrDown: upperOrDown, to: to } })
    },
  },
}
</script>
<style lang="scss" scoped>
.station-page {
  background-color: #f4f5f9;
}
.loading-div {
  height: calc(100vh - 46px);
}
.station-lines {
  height: calc(100vh - 46px);
  min-height: calc(100vh - 46px);
  overflow-y: auto;
  .line-group {
    background-color: #fff;
    //height: 40px;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    .float-left {
      display: inline-block;
      width: 60%;
      //float: left;
      .line-name {
        color: #373737;
        font-size: 18px;
        line-height: 20px;
        height: 20px;
      }
      .line-to {
        color: #686868;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
      }
    }
    .float-right {
      display: inline-block;
      // float: right;
      width: 30%;
      .line-neartext {
        color: #f99677;
        font-size: 18px;
        line-height: 20px;
        height: 20px;
      }
      .line-neardis {
        color: #686868;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
      }
    }
  }
}
</style>
